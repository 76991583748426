<template>
  <v-app>
    <v-card width="98%" class="mx-auto mt-5 pa-5">
      <v-card-title class="justify-center">
        特定商取引法に基づく表記
      </v-card-title>
      <v-card-text>
        <p v-html="this.clinic.sct_html" v-if="this.clinic"></p>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import { Firebase } from "../config/firebase";
import clinic from "@/mixins/clinic";
export default {
  name: "Welcome",
  data() {
    return {
      user: null,
      clinic: null,
    };
  },
  mixins: [clinic],
  created() {
    this.clinicFetchById();
  },
  computed: {
    clinic_id() {
      return this.$route.params.id;
    },
  },
  methods: {
    clinicFetchById: async function () {
      try {
        const clinicRef = await Firebase.firestore()
          .collection("Clinics")
          .doc(this.clinic_id);
        await clinicRef.get().then((doc) => {
          if (doc.exists) {
            this.clinic = doc.data();
            this.clinic.id = doc.id;
          } else {
            console.log("No clinic document!");
          }
          this.error = true;
        });
      } catch (e) {
        this.error = true;
        //this.$router.push({ name: "error", params: { message:  "表示できません。" } })
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
